
<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card>
      <div class="vx-row">
          <div class="vx-col w-full md:w-1/2">
              <vs-list>
                <vs-list-header title="Akses Role" color="primary"></vs-list-header>
                <draggable :list="menuakses" group="people" class="p-1 cursor-move ml-0" :move="onMoveRemove">
                  <vs-list-item v-for="(listItem, index) in menuakses" :key="index" class="ml-0">
                    <span v-if="listItem.name_parent" class="ml-0">{{ listItem.name_parent }} - </span>
                    <span class="font-semibold ml-0">{{ listItem.menu_title }}</span>
                    <vs-avatar slot="avatar" :text="listItem.menu_title" style="background: #F7941D none repeat scroll 0% 0%;"/>
                  </vs-list-item>
                </draggable>
              </vs-list>
          </div>
          <div class="vx-col w-full md:w-1/2">
              <vs-list>
                  <vs-list-header title="Menu" color="primary"></vs-list-header>
                  <draggable :list="menuitems" group="people" class="p-1 cursor-move ml-0" :move="onMoveAdd">
                  <vs-list-item v-for="(listItem, index) in menuitems" :key="index" style="ml-0">
                    <span v-if="listItem.name_parent" class="ml-0">{{ listItem.name_parent }} - </span> 
                    <span class="font-semibold ml-0">{{ listItem.menu_title }}</span>
                    <vs-avatar slot="avatar" :text="listItem.menu_title" />
                  </vs-list-item>
                  </draggable>
              </vs-list>
          </div>

      </div>
    </vx-card>

  </div>
  
</template>

<script>
import DataViewSidebar from './RoleMenuSidebar.vue'
//import DataEditSidebar from './ItemMenuEdit.vue'
import roleMenuModule from '@/stores/config/rolemenu.js'
import { mapActions } from 'vuex'
import draggable from 'vuedraggable'

export default {
  components: {
    DataViewSidebar,
    //DataEditSidebar
    draggable
  },
  data () {
    return {
      selected: [],
      // menuroles: [],
      isMounted: false,

      // Data Sidebar
      //addNewDataSidebar: false,
      //EditDataSidebar: false,
      //sidebarData: {},
      //sidebarEditData: {},
      hapus: false,
      hapusId: null,
      hapusMenu: null,
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    menuitems () {
      return this.$store.state.menurole.menuitems
    },
    menuakses () {
      return this.$store.state.menurole.menuakses
    }
  },
  methods: {
    ...mapActions({
      setAuth   : 'auth/set',
    }),
    close() {
      this.$emit('closed', false)
    },
    logout() {
      this.setAuth({}) 
      this.$router.push('/login').catch(() => {})
    },
    onMoveAdd(event) {
      this.$vs.loading()
      const obj = {
        menu: event.draggedContext.element.id,
        role: this.$route.params.slug
      }
      this.$store.dispatch('menurole/addMenuRole', obj)
      .then((response) => {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Berhasil',
          color  : 'success',
          text  : response.message,
        })
      })
      .catch(error => {
        this.$vs.loading.close()
        let {data} = error.response
        this.$vs.notify({
          title: 'Error',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    onMoveRemove(event) {
      this.$vs.loading()
      const obj = {
        menu: event.draggedContext.element.id,
        role: this.$route.params.slug
      }
      this.$store.dispatch('menurole/removeMenuRole', obj)
      .then((response) => {
        this.$vs.loading.close()
        this.$vs.notify({
          color : 'success',
          text : response.message,
        })
      })
      .catch(error => {
        this.$vs.loading.close()
        let {data} = error.response
        this.$vs.notify({
          title: 'Error',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  },
  created () {
    this.$store.registerModule('menurole', roleMenuModule)
    const filter = this.$route.params.slug
    this.$store.dispatch('menurole/fetchMenuRole', filter)
  },
  mounted () {
    this.isMounted = true
  },
  beforeDestroy () {
    this.$store.unregisterModule('menurole')
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 10px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 10px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 5px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
